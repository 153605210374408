<template>
  <div class="tables-container">
    <b-row>
      <b-col sm="4" md="4">
        <b-form-group
          label="Filtrar"
          label-cols="0"
          label-cols-sm="0"
          label-cols-md="3"
          label-cols-lg="4"
          label-size="sm"
          label-for="input-filter"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="filter"
              type="search"
              id="input-filter"
              placeholder="Escriba para filtrar"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''"
                >Limpiar</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col sm="4" md="4">
        <b-form-group
          label="Usuarios por página"
          label-cols="0"
          label-cols-sm="0"
          label-cols-md="3"
          label-cols-lg="4"
          label-size="sm"
          label-for="perPageSelect"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
          ></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="4" md="4" v-if="allow_search">
        <b-form-group
          label="Buscar"
          label-cols="0"
          label-cols-sm="0"
          label-cols-md="3"
          label-cols-lg="4"
          label-size="sm"
          label-for="input-search"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="search"
              type="search"
              id="input-search"
              placeholder="Escriba para buscar"
              @keypress.enter="searchUsers"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!search" @click="searchUsers"
                >Buscar</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      :items="filteredUsers"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon-left
      responsive="sm"
      small
      :selectable="true"
      :select-mode="select_mode"
      @row-selected="slotSelected"
      :caption-top="true"
      :filter="filter"
      @filtered="onFiltered"
    >
      <template v-slot:table-caption>Estudiantes</template>
      <template #cell(cohort)="data">
        {{ data.item.student_card ? data.item.student_card.cohort : "-" }}
      </template>
      <template #cell(profile)>
        <b-button size="sm" :to="{ name: 'AdmissionProfileView' }">Perfil</b-button>
      </template>
    </b-table>

    <b-row>
      <b-col sm="11" md="10"
        ><b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination
      ></b-col>
      <b-col sm="1" md="2">
        <b-button
          v-if="show_select_button"
          size="sm"
          class="rigth"
          @click="emitSelected"
          >Seleccionar</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "StudentList",
  props: {
    Sections: {
      type: Array,
      default: function () {
        return [];
      },
    },
    select_mode: {
      type: String,
      default: "single",
    },
    //select_mode: single, multi, range
    show_select_button: {
      type: Boolean,
      default: true,
    },
    users_to_remove: {
      type: Array,
      default: function () {
        return [];
      },
    },
    allow_search: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sortBy: "first_name",
      sortDesc: false,
      currentPage: 1,
      perPage: 5,
      fields: [
        { key: "rut", sortable: true, label: "RUT" },
        { key: "first_name", sortable: true, label: "Nombre" },
        { key: "last_name", sortable: true, label: "Apellidos" },
        { key: "cohort", sortable: true, label: "Cohorte" },
        { key: "profile", sortable: false, label: "Perfil" },
      ],
      filter: null,
      totalRows: 1,
      pageOptions: [5, 10, 15],
      selected_users: [],
      search: null,
      searched_users: [],
    };
  },
  computed: {
    ...mapGetters({
      users: names.USERS,
      sections: names.SECTIONS,
    }),
    filteredUsers() {
      let list = this.sectionsUsers;
      this.searched_users.forEach((element) => {
        const index = list.findIndex((x) => x.id == element.id);
        if (index == -1) list.push(element);
      });
      this.users_to_remove.forEach((user_id) => {
        const index = list.findIndex((x) => x.id == user_id);
        if (index != -1) list.splice(index, 1);
      });
      return list;
    },
    sectionsUsers() {
      let filtered_list = this.searched_users;
      this.Sections.forEach((section) => {
        section.students.forEach((student_id) => {
          const student = this.users.find((x) => x.id == student_id);
          const index = filtered_list.findIndex((x) => x.id == student_id);
          if (student && index == -1) filtered_list.push(student);
        });
      });
      return filtered_list;
    },
  },
  methods: {
    slotSelected(rows) {
      console.log("selected");
      this.$emit("selected", rows);
      this.selected_users = rows;
    },
    emitSelected() {
      this.$emit("selected_users", this.selected_users);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    searchUsers() {
      this.$restful
        .Get(`/authentication/user/?search=${this.search}`)
        .then((response) => {
          this.searched_users = response;
        });
    },
  },
  watch: {
    filteredUsers() {
      this.totalRows = this.filteredUsers.length;
    },
  },
  created() {
    this.Sections.forEach((section) => {
      section.professors.forEach((prof_id) => {
        this.$store.dispatch(names.FETCH_USER, prof_id);
      });
    });
  },
};
</script>

<style scoped>
.tables-container {
  width: auto;
  margin: 2px 10px;
}
</style>